import React from "react"

const Signatures = (props) => (
    <div>
        <div className="row justify-content-between">
            <div className="col-md-2">
                <img src="/images/clients/logos/client-logo-canarias-financia.png" alt="Fondo Canarias Financia" />
            </div>
            <div className="col-md-2">
                <img src="/images/clients/logos/client-logo-sodecan.png" alt="Sodecan" />
            </div>
            <div className="col-md-2">
                <img src="/images/clients/logos/client-logo-canarias-avanza.png" alt="Canarias Avanza" />
            </div>
            <div className="col-md-2">
                <img src="/images/clients/logos/client-logo-gobierno-canarias.png" alt="Gobierno de Canarias" />
            </div>
            <div className="col-md-2">
                <img src="/images/clients/logos/client-logo-fondo-desarrollo.png" alt="Fondo Europeo de Desarrollo Regional"/>
            </div>
        </div>
    </div>
)

export default Signatures

import React from 'react';
import {injectIntl} from 'gatsby-plugin-intl';
import Slider from "react-slick";

import "../styles/slider.less";

const SliderBanner = (props) => {
    
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return <>
        <Slider {...settings}>
            {props.slides.map((slide, index) => {
                return <div className="slide" key={index}>
                    <img src={slide.imagePath} alt="" />
                        <div className="slider-text">
                            <div className="container">
                                <div className={"row "+slide.alignment}>
                                    <div className="col-sm-12 col-md-6">
                                        <div>
                                            {
                                                slide.dangerous && slide.dangerous.title &&
                                                <h1
                                                    className="slide-title"
                                                    style={{color: slide.textColor}}
                                                    dangerouslySetInnerHTML={{__html: props.intl.formatMessage({id: slide.title})}}
                                                ></h1>
                                            }
                                            {
                                                (!slide.dangerous || !slide.dangerous.title) &&
                                                <h1
                                                    className="slide-title"
                                                    style={{color: slide.textColor}}
                                                >{props.intl.formatMessage({id: slide.title})}</h1>
                                            }
                                        <div>
                                            <p style={{color: slide.textColor}}>{props.intl.formatMessage({id: slide.subtitle})}</p>
                                        </div>
                                        {slide.button &&
                                            <div className="slide-button">
                                                <a href={slide.button.url} tabindex="0">{props.intl.formatMessage({id: slide.button.text})}</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </Slider>
    </>
}

export default injectIntl(SliderBanner);